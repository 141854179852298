import { Button, H2, Icon, Modal, Space, P } from "@dnb/eufemia"
import { close as Close } from "@dnb/eufemia/icons"
import { exclamation as Exclamation } from "@dnb/eufemia/icons"
import { navigate } from "gatsby"
import React, { useEffect, useContext } from "react"
import { AxiosError } from "axios"
import AuthContext from "../auth/context"

import "../css/main.css"

const ErrorModal = (props: { error?: Error | string }) => {
  const { user_ } = useContext(AuthContext)

  console.log(props)

  const error = user_?.error || props.error

  useEffect(() => {
    if ((error as string) === `Auth error`) {
      sessionStorage.clear()
    }
  }, [error])

  if (!error) {
    return null
  }

  const status = (error as AxiosError).response?.status ?? -1

  let message = ``
  if (status === 400) {
    message = `Du har allerede signert på denne filen.`
  } else if (status === 401) {
    message = `Du har ikke tilgang til å signere på filer.`
  } else if (status === 500) {
    message = `Du er ikke innlogget, eller økten har utløpt. Vennligst logg inn på nytt.`
  } else if (status === 403) {
    message = `Du er ikke innlogget, eller økten har utløpt. Vennligst logg inn på nytt.`
  } else if ((error as string) === `Auth error`) {
    message = `Du er ikke innlogget, eller økten har utløpt. Vennligst logg inn på nytt.`
  } else {
    message = `En feil har oppstått. Vennligst prøv igjen.`
  }

  const acknowledgeError = () => {
    sessionStorage.clear()
    navigate(`/logout`)
  }

  const acknowledgeWarning = () => {
    navigate(`/login-complete`)
  }

  return status !== -1 ? (
    status < 403 ? (
      <Modal className="Modal" open_state="opened" hide_close_button="true" trigger_hidden prevent_close="true">
        <div className="Icon--warning">
          <Icon icon={Exclamation} size="large" color={`#FDBB31`} title={`Advarsel`} />
        </div>
        <Space top="x-large">
          <H2 bottom="medium">Her gikk noe galt</H2>
          <P bottom="medium">{message}</P>
          <Button top="medium" onClick={acknowledgeWarning} text="Ok" />
        </Space>
      </Modal>
    ) : (
      <Modal className="Modal" open_state="opened" hide_close_button="true" trigger_hidden prevent_close="true">
        <div className="Icon--error">
          <Icon icon={Close} size="large" color={`#DC2A2A`} title={`Error`} />
        </div>
        <Space top="x-large">
          <H2 bottom="medium">Her gikk noe galt</H2>
          <P bottom="medium">{message}</P>
          <Button top="medium" onClick={acknowledgeError} text="Gå til forsiden" />
        </Space>
      </Modal>
    )
  ) : (
    <></>
  )
}

export default ErrorModal
